/* Paleta de colores */
:root {
  --reuse-morado:#7051a0;
  --reuse-azul:#0473c8;
  --reuse-celeste: #ccdaec;
  --reuse-bg: #f8f8f8;
  --reuse-verde: #09AA53;
}

//imports
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
@import '~bootstrap/scss/bootstrap.scss';

/* framework */



/* general */
body {background-color:var(--reuse-bg);margin: 0;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
#root{min-height: 600px;font-family: 'Poppins', sans-serif;}

/* Layout */
.bg-main{background-position:top center;background-image: url('../assets/img/bg-top.png');background-size: cover;background-repeat:no-repeat}

/* header */
.esquina{position:absolute;top:0;left:0;}


/* Home */
.delivery{width: 60%;margin: 0 auto;}
.wrap-estados{border-radius: 28px;background-color: $white;min-height:300px;margin-top:-30px}

/* buscador */
.input-group-lg > .btn, .input-group-lg > .form-control{padding: 0.5rem 1rem;font-size: 1.25rem;border-radius: 42rem;}
.input-group-lg > .btn{padding: 0.5rem 2rem;}
.input-group-lg > .form-control{padding-left: 50px;background-image: url('../assets/img/bg-input.png');background-repeat: no-repeat; background-position: 15px 15px; background-size: 20px }


/* etapas */
.etapas{transform: rotate(90deg) translate(0px, 230px);transform-origin: center;height: 310px;max-width: 350px;}
.etapas-wrapper{height:58px;background-color: #fafafa;box-shadow: inset 0px 0rem 0.5rem rgb(0 0 0 / 8%);border-radius: 96px;}
.etapa{width:25%;height:100%;display:flex; justify-content: center;align-items: center;flex-direction: column;position:relative}
.etapa:first-child::before{border-top-left-radius: 4px;border-bottom-left-radius: 4px;}
.etapa:last-child::after{border-top-right-radius: 4px;border-bottom-right-radius: 4px;}



.etapa::after{content:"";position: absolute;top:25px;left:0;background-color: #ececec;height:8px;width:50%}
.etapa::before{content:"";position: absolute;top:25px;right:0;background-color: #ececec;height:8px;width:50%}

.circulo{width:22px;height:22px;background-color: var(--reuse-celeste);border-radius: 50%;}
.etapa span{font-size:80%;margin-top:5px}
.moto {display:none;position: relative;}
.moto::before{content:"";position: absolute;top:-4px;left:-4px; width:38px; height:38px;border: solid 1px var(--reuse-azul);border-radius: 50%;}


.active::before{background-color: var(--reuse-azul);}
.active::after{background-color: var(--reuse-azul);}
.active .circulo-active,.active-moto .circulo-active{width:12px;height:12px;background-color: #02f0ff;border-radius: 50%;position:absolute;margin-top:0px;z-index:1}

.active-moto .moto{top:50px;width:30px;height:30px;border-radius: 50%;background-color: #fafafa;border-radius:50%;display:flex; justify-content:center;align-items:center;position:absolute;transform: rotate(-90deg);}
.active-moto::before{background-color: var(--reuse-azul);}
.active-moto::after{background-color: var(--reuse-azul);}

.entregado .etapa::before{background-color: var(--reuse-verde);}
.entregado .etapa::after{background-color: var(--reuse-verde);}
.entregado .etapa:last-child .circulo-active{background-color: #00FF19}
.entregado .moto::before{border: solid 1px var(--reuse-verde);}


.etapa:first-child::after{content: none !important;}
.etapa:last-child::before{content:none !important}

.etapa .text-dark {transform: rotate(270deg) translate(160px, 0px);position: absolute;width: 210px;}


@media (min-width: 768px) {
    
  .delivery{max-width:400px;width: 100%;}
  .bg-main{background-position:top center;background-image: url('../assets/img/bg-top.png');background-size: cover;background-repeat:no-repeat}

  .wrap-estados{margin-top:-70px}

  .etapas{transform: none; height: auto;max-width: 100%;}
  .etapas-wrapper{height:128px;}
  
  
  .etapa::after{top:48px;}
  .etapa::before{top:48px;}
  .active .circulo-active,.active-moto .circulo-active{margin-top:-24px;}
  .etapa .text-dark {transform: none;position: relative;width: auto;}

  .moto::before{content:"";position: absolute;top:5px;left:5px; width:65px; height:65px;border: solid 1px var(--reuse-azul);border-radius: 50%;}
  .active-moto .moto{top:-40px;width:74px;height:74px;border-radius: 50%;background-color: #fafafa;border-radius:50%;display:flex; justify-content:center;align-items:center;position:absolute;transform: none}
}